import React, { useState, useEffect } from 'react'
import { Form, Button, Modal } from "react-bootstrap"
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { BASE_URL } from '../../utils/constants';
import { Oval } from "react-loader-spinner"
import { toast } from 'react-toastify';
import { PiFaceMaskFill } from "react-icons/pi";
import InputMask from "react-input-mask"; // Import react-input-mask
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // Import datepicker styles
import moment from 'moment'
import { AiOutlineCalendar } from "react-icons/ai"; // Calendar Icon

import "./AddSurgery.scss"

const AddSurgery = ({newSurgery, user, logUserOut}) => {
  const navigate = useNavigate()
  const params = useParams()
  const { customPatientId, eventId } = params
  const [loading, setLoading] = useState(!newSurgery)
  const [study, setStudy] = useState("")
  const [eventStatus, setEventStatus] = useState("Active");
  const [eventDate, setEventDate] = useState(""); // Store as string for keyboard input
  const [dateUpdated, setDateUpdated] = useState(false)
  const [reasonForInactivity, setReasonForInactivity] = useState("")
  const [treatmentSide, setTreatmentSide] = useState("")
  const [disableButton, setDisableButton] = useState(false)
  const [error, setError] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false); // Toggle calendar visibility


  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const getSurgeryById = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
      const response = await axios.get(`${BASE_URL}/api/v1/event/${eventId}`, config)
      const event = response.data.event
      setStudy(event.study)
      setTreatmentSide(event.treatment_side)
      setEventDate(moment(event.event_date).format("DD/MM/YYYY")); // Format for display
      setEventStatus(event.event_status)
      setReasonForInactivity(event.reason_for_inactivity)
      setLoading(false)
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }

  useEffect(() => {
    if(!newSurgery) {
      getSurgeryById()
    }
  }, [])

  const deleteHandler = async (e) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
      await axios.delete(`${BASE_URL}/api/v1/event/${eventId}`, config)
      setDisableButton(true)
      toast.success('Surgery deleted', {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      setDisableButton(false)
      navigate(`/patients/${customPatientId}`)
      
    }
    catch (error) {
      console.log(error)
      const message = error.response && error.response.data.message ? error.response.data.message : error.message
      if (error.response && (error.response.status === 401 || message === 'Not authorized, token failed')) {
        logUserOut()
      }
    }
  }

  const handleDateChange = (date) => {
    setEventDate(moment(date).format("DD/MM/YYYY")); // Sync calendar selection with input
    setCalendarOpen(false); // Close calendar on selection
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Validate date format and required fields
    const isDateValid = moment(eventDate, "DD/MM/YYYY", true).isValid();
    if (!isDateValid || study === "" || treatmentSide === "" || (eventStatus === "Inactive" && reasonForInactivity === "")) {
      setError(true);
      return;
    }

    setError(false);

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      };
      const body = {
        customPatientId: customPatientId,
        eventId: eventId,
        eventType: "surgery",
        eventDate: moment(eventDate, "DD/MM/YYYY").format("YYYY-MM-DD"), // Convert to backend format
        eventStatus: eventStatus,
        study: study,
        treatmentSide: treatmentSide,
        reasonForInactivity: reasonForInactivity,
      };
      if (newSurgery) {
        setDisableButton(true);
        await axios.post(`${BASE_URL}/api/v1/event/new`, body, config);
        toast.success("Surgery created", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        setDisableButton(false);
      } else {
        await axios.put(`${BASE_URL}/api/v1/event/${eventId}`, body, config);
        toast.success("Surgery updated", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
      navigate(`/patients/${customPatientId}`);
    } catch (error) {
      console.log(error);
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (
        error.response &&
        (error.response.status === 401 || message === "Not authorized, token failed")
      ) {
        logUserOut();
      }
    }
  };

  return (
    <div className="add-surgery">
      {loading? (
        <Oval
          height={80}
          width={80}
          color="#4caeff"
          wrapperStyle={{}}
          wrapperClass="loader"
          visible={true}
          ariaLabel='oval-loading'
          secondaryColor="#4caeff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <>
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Deleting Event</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this surgery event and all the patient surveys linked to it?</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Back
              </Button>
              <Button variant="danger" onClick={deleteHandler}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
          <div className='add-surgery-header'>
          <h2 className="logo"><PiFaceMaskFill /> {newSurgery? "New" : "Edit"} Surgery <i>({customPatientId})</i></h2>
          </div>
          <Form onSubmit={submitHandler}>
            <div className="form-content">
              <div className="surgery-details">
              <h3>Surgery Details</h3>
              <Form.Group className="form-group">
                <Form.Label>Date</Form.Label>
                <div className="datepicker-container">
                  {/* Masked Input for Keyboard Entry */}
                  <InputMask
                    mask="99/99/9999"
                    value={eventDate}
                    onChange={(e) => setEventDate(e.target.value)}
                    placeholder="DD/MM/YYYY"
                    className={`form-control ${error && !moment(eventDate, "DD/MM/YYYY", true).isValid() ? "is-invalid" : ""}`}
                    onFocus={() => setCalendarOpen(true)} // Open calendar on focus
                  />
                  {/* Calendar Icon */}
                  <AiOutlineCalendar
                    className="calendar-icon"
                    onClick={() => setCalendarOpen(!calendarOpen)}
                  />
                </div>
                {error && !moment(eventDate, "DD/MM/YYYY", true).isValid() && (
                  <div className="text-danger">Invalid or missing date</div>
                )}
                {/* Date Picker (Dropdown) */}
                {calendarOpen && (
                  <div className="datepicker-wrapper-div">
                    <DatePicker
                    selected={eventDate && moment(eventDate, "DD/MM/YYYY", true).isValid() ? moment(eventDate, "DD/MM/YYYY").toDate() : null}
                    onChange={handleDateChange}
                    dateFormat="dd/MM/yyyy"
                    popperClassName="react-datepicker-popper"
                    inline
                    onClickOutside={() => setCalendarOpen(false)} // Close on outside click
                    showMonthDropdown /* Enable month selection */
                    showYearDropdown /* Enable year selection */
                    dropdownMode="select" /* Dropdown mode for month/year */
                    todayButton="Today" /* Add "Today" button */
                    />
                  </div>
                  
                )}
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Study</Form.Label>
                <Form.Select
                  style={{ border: error && study === "" ? "2px solid red" : "" }}
                  value={study}
                  onChange={(event) => setStudy(event.target.value)}>
                  <option value="" hidden>Select study</option>
                  <option value="elbow">Elbow</option>
                  <option value="shoulder arthroscopy">Shoulder Arthroscopy</option>
                  <option value="shoulder arthroplasty">Shoulder Arthroplasty</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Treatment side</Form.Label>
                <Form.Select
                  style={{ border: error && treatmentSide === "" ? "2px solid red" : "" }}
                  value={treatmentSide}
                  onChange={(event) => setTreatmentSide(event.target.value)}>
                  <option value="" hidden>Select side</option>
                  <option value="Left">Left</option>
                  <option value="Right">Right</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  style={{ border: error && eventStatus === "" ? "2px solid red" : "" }}
                  value={eventStatus}
                  onChange={(event) => setEventStatus(event.target.value)}>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Form.Select>
              </Form.Group>
              {
                eventStatus === "Inactive" && 
                <Form.Group className="form-group">
                  <Form.Label>Reason for Inactivity</Form.Label>
                  <Form.Select
                    style={{ border: error && reasonForInactivity === "" ? "2px solid red" : "" }}
                    value={reasonForInactivity}
                    onChange={(event) => setReasonForInactivity(event.target.value)}>
                      <option value="" hidden>Select side</option>
                      <option value="Lost to follow up">Lost to follow up</option>
                      <option value="Deceased">Deceased</option>
                      <option value="Opted out">Opted out</option>
                      <option value="Follow-up/Secondary surgery">Follow-up/Secondary surgery</option>
                      <option value="Changed hospital">Changed hospital</option>
                  </Form.Select>
                </Form.Group>
              }
              </div>   
            </div>
            <Button type="submit" variant="primary" disabled={disableButton}>
              {newSurgery? "Create Surgery" : "Update Surgery"}
            </Button>
            {
              !newSurgery &&
              <Button variant="danger" onClick={handleShow}>
                Delete Surgery
              </Button>
            }
            <Button variant="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Form>
        </>
      )}
    </div>
  )
}

export default AddSurgery